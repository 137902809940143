#visualizar-obra-page {
    position: relative;

    p.tempo-fotos {
        font-family: "Montserrat Alternates", sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #777;
    }

    .camera-item {
        position: relative;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        background-image: linear-gradient(#222, #444);
        box-sizing: border-box;
        color: #fff;
        overflow: hidden;

        .camera-header {
            position: relative;
            box-sizing: border-box;
            padding: 15px 15px 20px;
        }

        .fotos-info {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 25px;
        }

        .camera-item-footer {
            position: relative;
            width: 100%;
            border-top: 1px solid #d8d8d8;
            display: flex;
            align-items: center;

            button {
                position: relative;
                color: orange;
                outline: none;
                box-sizing: border-box;
                padding: 15px 0;
                width: 100%;
                font-size: 11px;
                font-weight: bold;
                border-right: 1px solid #d8d8d8;
                &:last-child {
                    border-right: 0;
                }
                &:hover {
                    background: #777;
                }
            }
        }
    }

}