@import "react-alice-carousel/lib/scss/alice-carousel.scss";

* {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
  cursor: pointer;
  outline: none;
  border: 0;
  background: transparent;
  font-family: "Open Sans", sans-serif;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.default-button {
  position: relative;
  width: 100%;
  border-radius: 4px;
  background-image: linear-gradient(orangered, orange);
  color: #fff;
  padding: 8px;
  box-sizing: border-box;
  font-weight: bold;
  margin-top: 10px;
}

.add-button {
  position: relative;
  color: #2fbbec;
  display: flex;
  align-items: center;

  &:hover {
    p {
      text-decoration: underline;
    }
    i {
      border-style: solid;
    }
  }

  p {
    font-size: 12.5px;
    font-weight: bold;
    margin: 0 !important;
  }

  i {
    color: #2fbbec;
    border: 1.5px dashed #2fbbec;
    padding: 6px;
    margin-right: 8px;
    border-radius: 50%;
    font-size: 22px;
    &.smaller {
      font-size: 14px;
      padding: 4px;
      border-width: 1px;
    }
  }
}

.admin-screen-container {
  position: relative;
  width: calc(100% - 250px);
  margin-left: 250px;
}

.side-menu-component-width {
  width: 250px;
  transition: 0.3s;
}

.side-menu-child-component-width {
  width: 250px;
  transition: 0.3s;
}

@media (max-width: 920px) {
  .admin-screen-container {
    width: 100%;
    margin-left: 0;
  }

  .side-menu-component-width {
    width: 100%;
  }

  .hide-side-menu {
    transform: translateX(-250px);
  }

  .hide-side-menu-all {
    pointer-events: none;
  }
}

.align-items-center {
  align-items: center;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.flex-8 {
  flex: 8;
}
.flex-9 {
  flex: 9;
}

input,
textarea {
  outline: none;
  // caret-color: rgb(29, 185, 84);
  border: 0;
  font-family: "Open Sans", sans-serif;
}

textarea {
  resize: none;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ReactModalPortal {
  position: relative;
  z-index: 999;
  background: rgba(0, 0, 0, 0.75);
}

.ReactModal__Content {
  box-sizing: border-box;
}

.spad {
  padding-top: 40px;
  padding-bottom: 40px;
}

.spad-smaller {
  padding-top: 20px;
  padding-bottom: 20px;
}

.spad-header {
  padding-top: calc(62px);
  padding-bottom: 30px;
}

p.error-message {
  color: rgb(237, 73, 86);
  font-size: 14px;
}

.default-input-container {
  position: relative;

  p {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  input,
  select {
    position: relative;
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0 15px;
    box-sizing: border-box;
    width: 100%;
  }
}

.obra-progress {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  padding: 2px 5px;
  color: #fff !important;
  margin: 10px 0;
  border-radius: 2px;
  font-size: 12px;
  font-weight: bold;

  &.andamento {
    background: #e18325;
  }
  &.finalizada {
    background: #000;
  }
}

.delete-label {
  position: relative;
  color: red;
  font-size: 12.5px;
  font-weight: bold;
}